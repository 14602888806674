import { useMemo  } from "react";
import { HardHatIcon, MapPinnedIcon, TriangleAlertIcon, VideoIcon } from "lucide-react";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

import { DateConverter } from "@/utils/DateConverter";
import { FilterVehicleType } from "@/models/FilterVehicleType";

interface VehiclesTableProps {
  vehicles: FilterVehicleType[];
};

export default function FleetDetailsCard({ vehicles }: VehiclesTableProps) {
  const totalRecordedHours = useMemo(() => (
    vehicles.reduce((total, vehicle) => (
      total += DateConverter.ConvertStringTimeToMinutes(vehicle.totalHorasDosVideos)
    ), 0)
  ), [vehicles]);

  const expectedTotalRecordedHours = 0;
  // const expectedTotalRecordedHours = useMemo(() => (
  //   vehicles.reduce((total, vehicle) => (
  //     total += Math.ceil(
  //       DateConverter.ConvertStringTimeToMinutes(vehicle.totalHorasDosVideos)
  //         / (vehicle.percentualEmNumero || 1),
  //     )
  //   ), 0)
  // ), [vehicles]);

  const totalServiceHours = useMemo(() => (
    vehicles.reduce((total, vehicle) => (
      total += DateConverter.ConvertStringTimeToMinutes(vehicle.totalDeHorasEmServico)
    ), 0)
  ), [vehicles]);

  const totalTravelHours = useMemo(() => (
    vehicles.reduce((total, vehicle) => (
      total += DateConverter.ConvertStringTimeToMinutes(vehicle.totalDeHorasEmDeslocamento)
    ), 0)
  ), [vehicles]);

  const alertCount = useMemo(() => (
    vehicles.reduce((total, vehicle) => (
      total += vehicle?.quantidadeTotalDeAlertas || 0
    ), 0)
  ), [vehicles]);

  return (
    <CardHeader>
      <CardTitle className="mb-4">
        {`${vehicles.length} veículo${vehicles.length > 1 ? 's' : ''} no período`}
      </CardTitle>
      <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4 mb-8">
        <Card x-chunk="dashboard-01-chunk-3">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Horas de gravação</CardTitle>
            <VideoIcon className="h-5 w-5 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">
              {DateConverter.ConvertMinutesToTimeString(totalRecordedHours)}
              {!!expectedTotalRecordedHours
                ? ` / ${DateConverter.ConvertMinutesToTimeString(expectedTotalRecordedHours)}`
                : ''}
            </p>
          </CardContent>
        </Card>
        <Card x-chunk="dashboard-01-chunk-3">
          <CardHeader
            className="flex flex-row items-center justify-between space-y-0 pb-2"
          >
            <CardTitle className="text-sm font-medium">
              Horas em serviço
            </CardTitle>
            <HardHatIcon className="h-5 w-5 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">
              {DateConverter.ConvertMinutesToTimeString(totalServiceHours)}
            </p>
          </CardContent>
        </Card>
        <Card x-chunk="dashboard-01-chunk-3">
          <CardHeader
            className="flex flex-row items-center justify-between space-y-0 pb-2"
          >
            <CardTitle className="text-sm font-medium">
              Horas em deslocamento
            </CardTitle>
            <MapPinnedIcon className="h-5 w-5 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">
              {DateConverter.ConvertMinutesToTimeString(totalTravelHours)}
            </p>
          </CardContent>
        </Card>
        <Card x-chunk="dashboard-01-chunk-3">
          <CardHeader
            className="flex flex-row items-center justify-between space-y-0 pb-2"
          >
            <CardTitle className="text-sm font-medium">
              Quantidade de alertas
            </CardTitle>
            <TriangleAlertIcon className="h-5 w-5 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <p className="text-2xl font-bold">{alertCount}</p>
          </CardContent>
        </Card>
      </div>
    </CardHeader>
  );
};
